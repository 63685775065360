import { Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-quest-form',
  template: `
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <form #questForm="ngForm" (ngSubmit)="onSubmit(questForm)">
      <mat-dialog-content class="mat-typography">
        <div class="">
          <div class="row">
            <div class="col-md-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Nom de la quête</mat-label>
                <input matInput ngModel name="name" required>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Date de début</mat-label>
                <input matInput [matDatepicker]="picker" ngModel name="startDate" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Date de fin</mat-label>
                <input matInput [matDatepicker]="picker2" ngModel name="endDate" required>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-slide-toggle ngModel name="active" [ngModel]="false">Actif</mat-slide-toggle>
            </div>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Annuler</button>
        <button mat-flat-button color="primary" type="submit" [disabled]="!questForm.valid">
          {{ data.submitText }}
        </button>
      </mat-dialog-actions>
    </form>
  `
})
export class QuestFormComponent {
  constructor(
    public dialogRef: MatDialogRef<QuestFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onSubmit(form: NgForm) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    }
  }
}
