import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment as env } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { QuestService } from 'src/app/services/quest.service';
import { QuestFormComponent } from './quest-form.component';
import { QuestObjectiveFormComponent } from './quest-objective-form.component';

export interface Section {
  name: string;
  updated: Date;
}

export interface QuestObjective {
  id: number;
  active: boolean;
  description: string;
  earnableTrophies: number;
  requiredAction: string;
  requiredNumber: number;
  targetId?: string;
}

export interface Quest {
  id: number;
  name: string;
  active: boolean;
  startDate: string;
  endDate: string;
  objectives: QuestObjective[];
}

@Component({
  selector: 'quests',
  templateUrl: './quest.component.html',
})
export class QuestComponent implements OnInit {

  private snackBar: MatSnackBar;

  constructor(private questService: QuestService, snackBar: MatSnackBar, private http: HttpClient, private dialog: MatDialog) {
    this.snackBar = snackBar;
  }

  dataSource: Quest[] = []; // Initialisation avec un tableau vide

  ngOnInit() {
    this.getAvailableQuests();
  }

  getAvailableQuests() {
    this.questService.getAvailableQuests().subscribe({
      next: (result: any) => {
        this.dataSource = result?.data?.quests || [];
      },
      error: (error: any) => {
        console.error('Erreur lors de la récupération des quêtes:', error);
        this.snackBar.open('Erreur lors du chargement des quêtes', 'Fermer', {
          duration: 3000
        });
        this.dataSource = []; // En cas d'erreur, on s'assure d'avoir un tableau vide
      }
    });
  }

  createQuest(formValue: any) {
    this.questService.createQuest({
      name: formValue.name,
      active: formValue.active || false,
      startDate: formValue.startDate,
      endDate: formValue.endDate
    }).subscribe({
      next: () => {
        this.snackBar.open('La quête a été créée avec succès', 'Fermer', { duration: 2000 });
        this.getAvailableQuests();
      },
      error: (error) => {
        this.snackBar.open('Erreur lors de la création', 'Fermer', { duration: 3000 });
      }
    });
  }

  createQuestObjective(formValue: any) {
    if (!formValue?.questId) {
      this.snackBar.open('Erreur: données invalides', 'Fermer', { duration: 2000 });
      return;
    }

    this.questService.createQuestObjective({
      questId: formValue.questId,
      active: formValue.active || false,
      description: formValue.description,
      earnableTrophies: formValue.earnableTrophies,
      requiredAction: formValue.requiredAction,
      requiredNumber: formValue.requiredNumber,
      targetId: formValue.targetId
    }).subscribe({
      next: () => {
        this.snackBar.open('Objectif créé avec succès', 'Fermer', { duration: 2000 });
        this.getAvailableQuests();
      },
      error: (error) => {
        this.snackBar.open('Erreur lors de la création', 'Fermer', { duration: 3000 });
      }
    });
  }

  //calculate the number of days between two dates
  calculateDays(startDate: string, endDate: string) {
    let date1: any = new Date(startDate);
    let date2: any = new Date(endDate);
    let diffDays: any = Math.ceil((date2 - date1) / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  openQuestForm() {
    const dialogRef = this.dialog.open(QuestFormComponent, {
      width: '600px',
      data: {
        title: 'Nouvelle quête',
        submitText: 'Créer la quête'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('create quest', result);
        this.createQuest(result);
      }
    });
  }

  openObjectiveForm(questId: number) {
    const dialogRef = this.dialog.open(QuestObjectiveFormComponent, {
      width: '600px',
      data: {
        questId: questId, // Ajouter l'ID de la quête dans les données
        title: 'Nouvel objectif',
        submitText: 'Ajouter l\'objectif',
        availableActions: QuestService.OBJECTIVES_ACTIONS
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Creating objective for quest:', questId, result);
        this.createQuestObjective({
          ...result,
          questId: questId
        });
      }
    });
  }

  hideQuest(questId: number) {
    const quest = this.dataSource.find(q => q.id === questId);
    if (quest) {
      quest.active = false;
      console.log('hide quest', quest);
      /*
      this.questService.updateQuest(quest).subscribe(() => {
        this.snackBar.open('Quête masquée avec succès', 'Fermer', { duration: 2000 });
      });
      */
    }
  }

  showQuest(questId: number) {
    const quest = this.dataSource.find(q => q.id === questId);
    if (quest) {
      quest.active = true;
      console.log('show quest', quest);
      /*
      this.questService.updateQuest(quest).subscribe(() => {
        this.snackBar.open('Quête affichée avec succès', 'Fermer', { duration: 2000 });
      });
      */
    }
  }

  deleteQuest(questId: number) {
    Swal.fire({
      title: 'Êtes-vous sûr ?',
      text: 'Cette action est irréversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('delete quest', questId);
        this.questService.deleteQuest(questId).subscribe(() => {
          this.getAvailableQuests();
          this.snackBar.open('Quête supprimée avec succès', 'Fermer', { duration: 2000 });
        });
      }
    });
  }

  deleteObjective(objectiveId: number) {
    Swal.fire({
      title: 'Êtes-vous sûr ?',
      text: 'Cette action est irréversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('delete objective', objectiveId);
        this.questService.deleteQuestObjective(objectiveId).subscribe(() => {
          this.getAvailableQuests();
          this.snackBar.open('Objectif supprimé avec succès', 'Fermer', { duration: 2000 });
        });
      }
    });
  }

}
