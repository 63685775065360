import { gql } from '@apollo/client/core';

export const QUEST_FIELDS = gql`
    fragment QuestFields on Quest {
        id
        name
        active
        startDate 
        endDate 
        objectives {
            id
            active
            description 
            earnableTrophies
            requiredAction
            requiredNumber
            targetId
        }
    }
`;
