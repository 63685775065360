import { gql } from '@apollo/client/core';
import { QUEST_FIELDS } from './fragments';

export const GET_AVAILABLE_QUESTS = gql`
    query quests {
        quests {
            ...QuestFields
        }
    }
    ${QUEST_FIELDS}
`;