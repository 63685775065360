import { Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-quest-objective-form',
  template: `
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <form #objectiveForm="ngForm" (ngSubmit)="onSubmit(objectiveForm)">
      <mat-dialog-content class="mat-typography">
        <div class="">
          <div class="row">
            <div class="col-md-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Action requise</mat-label>
                <mat-select ngModel name="requiredAction" required>
                  <mat-option *ngFor="let action of data.availableActions" [value]="action">
                    {{action}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Nombre requis</mat-label>
                <input type="number" matInput ngModel name="requiredNumber" required>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Trophées gagnables</mat-label>
                <input type="number" matInput ngModel name="earnableTrophies" required>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Description</mat-label>
                <input matInput ngModel name="description" required>
              </mat-form-field>
            </div>

<!--             <div class="col-md-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>ID Cible (optionnel)</mat-label>
                <input matInput ngModel name="targetId">
              </mat-form-field>
            </div> -->

            <div class="col-md-6 d-flex align-items-center">
              <mat-slide-toggle ngModel name="active" [ngModel]="false" class="m-t-10">Actif</mat-slide-toggle>
            </div>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Annuler</button>
        <button mat-flat-button color="primary" type="submit" [disabled]="!objectiveForm.valid">
          {{ data.submitText }}
        </button>
      </mat-dialog-actions>
    </form>
  `
})
export class QuestObjectiveFormComponent {
  constructor(
    public dialogRef: MatDialogRef<QuestObjectiveFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onSubmit(form: NgForm) {
    if (form.valid) {
      this.dialogRef.close(form.value);
    }
  }
}
