<!-- ============================================================== -->
<!-- Simple four boxes Row -->
<!-- ============================================================== -->

<div class="col-lg-12">
  <mat-card class="cardWithShadow">
    <mat-card-content class="p-24">

      <div class="col-12">
        <div class="d-flex justify-content-between align-items-center m-b-16">

          <div class="d-flex align-items-center">
            <h2 class="m-0 mat-headline-2 f-w-600">Quêtes</h2>
          </div>

          <div class="d-flex align-items-center">
            <button mat-icon-button (click)="openQuestForm()">
              <mat-icon>add</mat-icon>
            </button>
          </div>

        </div>

        <!-- --------------------------------------------------- -->
        <!-- List with sections -->
        <!-- --------------------------------------------------- -->

        <mat-card class="b-1 shadow-none" *ngFor="let quest of dataSource"
          matTooltip="{{ quest.startDate | date: 'dd/MM/yyyy' }} - {{ quest.endDate | date: 'dd/MM/yyyy' }}">

          <div class="d-flex justify-content-between align-items-center m-x-16 m-y-8">

            <div class="d-flex align-items-center">
              <mat-icon class="m-r-8">task_alt</mat-icon>
              <h3 class="m-0 mat-headline-2 f-w-600 m-r-8">
                {{ quest.name }}
              </h3>
              <span class="rounded f-w-600 p-6 p-y-4 f-s-12 m-r-8" [ngClass]="{'text-success bg-light-success': quest.active, 
                                   'text-warning bg-light-warning': !quest.active }">
                {{ quest.active ? 'actif' : 'inactif' }}
              </span>

            </div>

            <div class="d-flex align-items-center">

<!--               <button mat-icon-button matTooltip="Masquer la quête" (click)="hideQuest(quest.id)" *ngIf="quest.active">
                <mat-icon>visibility_off</mat-icon>
              </button>
              
              <button mat-icon-button matTooltip="Afficher la quête" (click)="showQuest(quest.id)" *ngIf="!quest.active">
                <mat-icon>visibility_on</mat-icon>
              </button>
 -->
              <button mat-icon-button color="primary" matTooltip="Supprimer la quête" (click)="deleteQuest(quest.id)">
                <mat-icon>delete_outline</mat-icon>
              </button>
              
            </div>

          </div>
          <mat-card-content>
            <mat-list>
              <mat-list-item *ngFor="let objective of quest.objectives || []">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <span class="rounded f-w-900 p-6 p-y-4 f-s-16 m-l-8 m-r-8">
                      {{ objective.earnableTrophies }} 🏆
                    </span>
                    <div class="m-r-8">
                      <div class="mat-body-2 f-w-600">
                        {{ objective.description | titlecase }}
                        ({{ objective.requiredNumber + " fois"}})
                      </div>
                      <div class="f-s-12" *ngIf="objective.targetId">
                        ID Cible: {{ objective.targetId }}
                      </div>
                    </div>
                    <span class="rounded f-w-600 p-6 p-y-4 f-s-12" [ngClass]="{'text-success bg-light-success': objective.active, 
                                    'text-warning bg-light-warning': !objective.active}">
                      {{ objective.active ? 'actif' : 'inactif' }}
                    </span>
                  </div>
                  <button mat-icon-button color="primary" matTooltip="Supprimer la quête" (click)="deleteObjective(objective.id)">
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </div>
              </mat-list-item>

              <mat-divider *ngIf="quest.objectives?.length && quest.objectives.length > 0"></mat-divider>

              <mat-list-item>
                <button mat-flat-button (click)="openObjectiveForm(quest.id)">
                  <mat-icon>add</mat-icon>
                  <span>Ajouter un objectif</span>
                </button>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </mat-card>

        <div *ngIf="!dataSource || dataSource.length === 0" class="text-center p-24">
          <mat-icon>
            sentiment_very_dissatisfied
          </mat-icon>
          <p class="mat-body-1">Aucune quête disponible pour le moment</p>
        </div>

      </div>

    </mat-card-content>
  </mat-card>
</div>