import { HttpClient } from '@angular/common/http';
import {
  Component,
  Input,
  OnInit,
  Inject,
  NgZone,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Observer, Subscription } from 'rxjs';
import { EventSourcePolyfill } from 'ng-event-source';
import Swal from 'sweetalert2';
import { CategoryService } from 'src/app/services/category.service';
import { ChallengeService } from 'src/app/services/challenge.service';
import { DateService } from 'src/app/services/date.service';

export interface DialogData {
  proposition: any;
  challenge: any;
  reason: string;
}

@Component({
  selector: 'challengeform',
  templateUrl: 'challenge-form.component.html',
})
export class AppChallengeFormComponent implements OnInit {
  @ViewChild('nameInput') nameInput: ElementRef;

  categoryDataSource: any;
  errorMessages: String;
  sub: Subscription;

  loading = false;

  isTagUnique = true;

  uploadForm: FormGroup;

  constructor(
    private categoryService: CategoryService,
    private challengeService: ChallengeService,
    public dateService: DateService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    public dialogRef: MatDialogRef<AppChallengeFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private zone: NgZone,
  ) {
  }

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({
      video: [''],
    });
    this.getCategories();
    this.preloadForm();

  }

  preloadForm() {
    if (this.data.proposition) {
      this.data.challenge = {
        id: '',
        description: this.data.proposition
          ? this.data.proposition.description
          : '',
        category: '',
        name: '',
        tag: '',
        startDate: '',
        endDate: '',
        type: '',
        videoUrl: '',
        earnableTrophies: 100,
      };
    }

    if (this.data.challenge == null) {
      this.data.challenge = {
        id: '',
        description: '',
        category: '',
        name: '',
        tag: '',
        startDate: '',
        endDate: '',
        type: '',
        videoUrl: '',
        earnableTrophies: 100,
      };
    }
  }

  transformPropositionInChallenge(from: NgForm) {

    if (!this.isTagUnique) {
      this.errorMessages = "Ce tag est déjà utilisé par un autre challenge.";
      return;
    }

    this.loading = true;

    let formData: FormData = new FormData();
    formData.append('categoryId', from.value.categoryId);
    formData.append('name', from.value.name);
    formData.append('tag', from.value.tag);
    formData.append('description', from.value.description);
    formData.append('startDate', from.value.startDate);
    formData.append('endDate', from.value.endDate);
    formData.append('type', from.value.type);

    formData.append('video', this.uploadForm.get('video')?.value);

    this.http
      .post<any>(
        `${env.restUrl}/challenges/propositions/${this.data.proposition.id}/transform`,
        formData,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token') + '',
            ContentType: 'multipart/form-data',
            'Session-Id': String(localStorage.getItem('sessionId')),
          },
        }
      )
      .subscribe({
        next: (result) => {
          console.log(result);
          Swal.fire(
            'Success!',
            'Proposition transformed successfully',
            'success'
          );
          this.loading = false;
          this.dialogRef.close();
        },
        error: (error) => {
          this.loading = false;
          this.errorMessages =
            error.error.message ?? error.error.errors.message;
          console.log(error);
        },
      });
  }

  createChallenge(from: NgForm) {

    if (!this.isTagUnique) {
      this.errorMessages = "Ce tag est déjà utilisé par un autre challenge.";
      return;
    }

    this.loading = true;

    let formData: FormData = new FormData();
    formData.append('categoryId', from.value.categoryId);
    formData.append('name', from.value.name);
    formData.append('tag', from.value.tag);
    formData.append('description', from.value.description);
    formData.append('startDate', this.dateService.transformSqlDate(from.value.startDate));
    formData.append('endDate', this.dateService.transformSqlDate(from.value.endDate));
    formData.append('type', from.value.type);
    formData.append('earnableTrophies', from.value.earnableTrophies);

    formData.append('video', this.uploadForm.get('video')?.value);

    this.http
      .post<any>(`${env.restUrl}/challenges`, formData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token') + '',
          ContentType: 'multipart/form-data',
          'Session-Id': String(localStorage.getItem('sessionId')),
        },
      })
      .subscribe({
        next: (result) => {
          console.log(result);
          Swal.fire('Success!', 'Challenge created successfully', 'success');
          this.loading = false;
          this.dialogRef.close();
        },
        error: (error) => {
          this.loading = false;
          this.errorMessages = error.error.errors[0];
          console.log(error);
        },
      });
  }

  updateChallenge(from: NgForm) {

    //if the tag has changed, we need to check if it is unique
    if (from.value.tag != this.data.challenge.tag) {
      //check if the tag is unique
      if (!this.isTagUnique) {
        this.errorMessages = "Ce tag est déjà utilisé par un autre challenge.";
        return;
      }
    } else {
      //if the tag has not changed, we can assume it is unique
      this.isTagUnique = true;
      this.errorMessages = "";
    }

    this.loading = true;

    let formData: FormData = new FormData();
    formData.append('categoryId', from.value.categoryId);
    formData.append('name', from.value.name);
    formData.append('tag', from.value.tag);
    formData.append('description', from.value.description);
    formData.append('startDate', from.value.startDate.replace('T', ' '));
    formData.append('endDate', from.value.endDate.replace('T', ' '));
    formData.append('type', from.value.type);
    formData.append('earnableTrophies', from.value.earnableTrophies);

    formData.append('video', this.uploadForm.get('video')?.value);

    this.http
      .patch<any>(
        `${env.restUrl}/challenges/${this.data.challenge.id}`,
        formData,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token') + '',
            ContentType: 'multipart/form-data',
            'Session-Id': String(localStorage.getItem('sessionId')),
          },
        }
      )
      .subscribe({
        next: (result) => {
          console.log(result);
          Swal.fire('Success!', 'Challenge updated successfully', 'success');
          this.loading = false;
          this.dialogRef.close();
        },
        error: (error) => {
          this.loading = false;
          this.errorMessages =
            error.error.message ?? error.error.errors.message;
          console.log(error);
        },
      });
  }

  getCategories() {
    this.categoryService.getCategories().subscribe((result: any) => {
      this.categoryDataSource = result.data.categories;
    });
  }

  deleteChallenge() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this challenge!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        this.challengeService.deleteChallenge(this.data.challenge.id)
          .subscribe({
            next: (result) => {
              Swal.fire(
                'Deleted!',
                'Your challenge has been deleted.',
                'success'
              );
              this.dialogRef.close();
            },
            error: (error) => {
              Swal.fire('Error!', 'Error deleting challenge', 'error');
            },
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your challenge is safe :)', 'error');
      }
    });
  }

  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get(event.target.name)?.setValue(file);
    }
  }

  checkUnicity(event: any) {

    const text = event.target.value;

    if (text.length < 2) {
      return;
    }

    this.challengeService.checkUnicity(text).subscribe({
      next: (result: any) => {
        if (result.data.checkUnicity == true) {
          this.errorMessages = "Ce tag est déjà utilisé par un autre challenge.";
          this.isTagUnique = false;
        } else {
          this.errorMessages = "";
          this.isTagUnique = true;
        }
      },
      error: (error) => {
        console.log(error);
      },
    });

  }

}
