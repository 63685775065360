import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { AppManagementComponent } from './pages/access/access.component';

import { AuthGuard } from './auth.guard';
import { AppChallengeComponent } from './pages/challenge/challenge.component';
import { AppPropositionComponent } from './pages/proposition/proposition.component';
import { AppReportComponent } from './pages/report/report.component';
import { AppSearchComponent } from './pages/search/search.component';
import { QuestComponent } from './pages/quest/quest.component';

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule),
      },
      {
        path: 'challenges',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule),
        component: AppChallengeComponent,
      },
      {
        path: 'quests',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule),
        component: QuestComponent,
      },
      {
        path: 'propositions',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule),
        component: AppPropositionComponent,
      },
      {
        path: 'signalements',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule),
        component: AppReportComponent,
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule),
        component: AppSearchComponent,
      },
      {
        path: 'access',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule),
        component: AppManagementComponent,
      },
      {
        path: 'ui-components',
        loadChildren: () =>
          import('./pages/ui-components/ui-components.module').then(
            (m) => m.UicomponentsModule
          ),
      },
    ],
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./pages/authentication/authentication.module').then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  
}
