import { gql } from '@apollo/client/core';

export const USER_BASE_FIELDS = gql`
    fragment userBaseFields on User {
        uuid
        username
        accountName
        email
        bio
        avatarUrl
        active
        birthday
        country
        zipCode
        city
        trophies
        nbParticipations
        nbFollowedBy
        nbFollows
        isBanned
        rank {
            id
            label
            name
            requiredTrophies
        }
    }
`;

export const USER_FIELDS = gql`
    fragment userFields on User {
        ...userBaseFields
    }
    ${USER_BASE_FIELDS}
`;
