<mat-card class="cardWithShadow col">
  <mat-card-content class="p-10 m-10">

    <div class="d-flex align-items-start">

      <div class="d-flex flex-column">

        <div class="f-s-16 f-w-600 m-b-10">
          ID: {{ challenge.id }}
        </div>
        <div class="d-flex flex-column" (click)="this.AppChallengeComponent.fullScreenMedia(challenge.videoUrl)"
          style="cursor:pointer; position: relative;">
          <div style="position: absolute;">
            <mat-icon class="mat-icon-12" style="color: white;">play_arrow</mat-icon>
          </div>
          <video [hidden]="!challenge.boomerangUrl" [src]="challenge.boomerangUrl" width="180" class="rounded" loop
            autoplay></video>
          <img [hidden]="challenge.boomerangUrl || !challenge.thumbnailUrl" [src]="challenge.thumbnailUrl" width="180"
            class="rounded" />
          <h6 class="mat-subtitle-1 f-s-14 f-w-600">
            <div class="d-flex align-items-center">
              <img [hidden]="!challenge.creator.avatarUrl" [src]="challenge.creator.avatarUrl" width="30" height="30"
                class="rounded-circle" />
              <img [hidden]="challenge.creator.avatarUrl" src="/assets/images/profile/user-1.jpg" width="30" height="30"
                class="rounded-circle" />
              <div class="m-l-8">
                <h6 class="mat-subtitle-1 f-s-14 f-w-600">
                  {{ challenge.creator.username }}
                </h6>
                <h6 class="mat-subtitle-2 f-s-10 f-w-600">
                  {{ challenge.creator.rank.name }}
                </h6>
              </div>
            </div>
          </h6>
        </div>
      </div>

      <div class="m-l-16 w-100">
        <h6 class="mat-subtitle-1 f-w-600 text-primary">
          <div class="d-flex justify-content-between">
            <div> {{ challenge.name }} <span class="mat-subtitle-2 f-s-14 f-w-600">#{{ challenge.tag }} </span></div>
            <div [hidden]="challenge.rewards == null || challenge.rewards.length == 0">
              <mat-icon class="mat-icon-12">redeem</mat-icon>
            </div>
          </div>
        </h6>
        <mat-divider class="m-y-8"></mat-divider>

        <span class="mat-body-1">
          {{ challenge.description }}
        </span>

        <mat-divider class="m-y-8"></mat-divider>

        Catégorie :
        <span class="rounded f-w-600 p-6 p-y-4 f-s-12"
          [style]="'background-color: ' + challenge.category.color+'40; border: 1px solid '+challenge.category.color">
          {{ challenge.category.icon }} {{ challenge.category.name }}
        </span>

        <mat-divider class="m-y-8"></mat-divider>

        <h6 class="mat-subtitle-1 f-s-14 f-w-600">
          Statistiques :
        </h6>

        <div *ngIf="challenge.category" class="text-center rounded">
          <div class="d-flex">
            <div class="d-flex flex-column align-items-center m-10">
              <h6 class="mat-subtitle-1 f-s-14 f-w-600 text-primary">
                {{ challenge.views | number }}
              </h6>
              <span class="mat-body-1 f-s-12 f-w-600">
                Vues
              </span>
            </div>
            <div class="d-flex flex-column align-items-center m-10">
              <h6 class="mat-subtitle-1 f-s-14 f-w-600 text-primary">
                {{ challenge.nbLikes | number }}
              </h6>
              <span class="mat-body-1 f-s-12 f-w-600">
                Likes
              </span>
            </div>
            <div class="d-flex flex-column align-items-center m-10">
              <h6 class="mat-subtitle-1 f-s-14 f-w-600 text-primary">
                {{ challenge.nbComments | number }}
              </h6>
              <span class="mat-body-1 f-s-12 f-w-600">
                Commentaires
              </span>
            </div>
            <div class="d-flex flex-column align-items-center m-10">
              <h6 class="mat-subtitle-1 f-s-14 f-w-600 text-primary">
                {{ challenge.nbShares | number }}
              </h6>
              <span class="mat-body-1 f-s-12 f-w-600">
                Partages
              </span>
            </div>
            <div class="d-flex flex-column align-items-center m-10">
              <h6 class="mat-subtitle-1 f-s-14 f-w-600 text-primary">
                {{ challenge.nbParticipations | number }}
              </h6>
              <span class="mat-body-1 f-s-12 f-w-600">
                Participations
              </span>
            </div>
          </div>
        </div>

        <mat-divider class="m-y-8"></mat-divider>

        <ng-template [ngIf]="challenge.startDate && challenge.endDate">

          <div class="d-flex align-items-center">
            <div>
              <mat-icon color="primary">calendar_today</mat-icon>
            </div>
            <div class="m-l-8">
              <h6 class="mat-subtitle-1 f-s-12 f-w-600">
                {{ challenge.startDate | date: 'dd/MM/yyyy' }} - {{ challenge.endDate | date: 'dd/MM/yyyy' }} <b
                  class="text-primary">(Durée du challenge:
                  {{this.AppChallengeComponent.calculateDays(challenge.startDate,
                  challenge.endDate)}} jours)</b>
              </h6>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <div class="">
              <span class="bg-light-accent text-accent rounded f-w-600 p-6 p-y-4 f-s-12">
                {{this.AppChallengeComponent.checkChallengeStatusText(challenge.startDate, challenge.endDate,
                challenge.status)}}
              </span>
            </div>
          </div>

        </ng-template>

        <mat-divider class="m-y-8"></mat-divider>

        <div class="d-flex flex-row align-items-center justify-content-between">
          <!-- select a status -->
          <ng-template [ngIf]="challenge.status" class="m-r-10">
            <mat-form-field appearance="outline" class="">
              <mat-label>Change status</mat-label>
              <mat-select [(value)]="challenge.status" #status
                (selectionChange)="this.AppChallengeComponent.updateChallengeStatus(challenge.id, status.value)">
                <mat-option *ngFor="let status of this.AppChallengeComponent.statusList" [value]="status.value">
                  {{ status.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-template>

          <div>

            <button mat-flat-button class="m-r-10" [color]="challenge.rewards == null || challenge.rewards.length == 0 ? 'warn' : 'accent'"
              (click)="this.AppChallengeComponent.openRewardPopup(challenge)">
              <mat-icon>
                {{ challenge.rewards == null || challenge.rewards.length == 0 ? 'add' : 'redeem' }}
              </mat-icon> 
              {{ challenge.rewards == null || challenge.rewards.length == 0 ? 'Ajouter une récompense' : 'Voir les récompenses ( ' + challenge.rewards.length + ' )' }}
            </button>

            <button mat-flat-button class="m-r-10" color="primary"
              (click)="this.AppChallengeComponent.updateChallenge(challenge)">
              <mat-icon>edit</mat-icon> Modifier le challenge
            </button>

          </div>

        </div>

      </div>
    </div>
  </mat-card-content>
</mat-card>