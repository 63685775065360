import { HttpClient } from '@angular/common/http';
import {
  Component,
  Input,
  OnInit,
  Inject,
  NgZone,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { RewardService } from 'src/app/services/reward.service';

export interface DialogData {
  challenge: any;
}

@Component({
  selector: 'rewards',
  templateUrl: 'rewards.component.html',
})
export class AppRewardsComponent implements OnInit {
  @ViewChild('nameInput') nameInput: ElementRef;

  uploadForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    public dialogRef: MatDialogRef<AppRewardsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private zone: NgZone,
    private rewardService: RewardService,
  ) { }

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({
      image: [''],
    });
  }

  addReward(from: NgForm) {

    let formData: FormData = new FormData();
    formData.append('name', from.value.name);
    formData.append('description', from.value.description);
    formData.append('assignedRankingPosition', from.value.assignedRankingPosition);

    formData.append('image', this.uploadForm.get('image')?.value);

    this.http
      .post<any>(`${env.restUrl}/challenges/`+ this.data.challenge.id +`/rewards`, formData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token') + '',
          ContentType: 'multipart/form-data',
          'Session-Id': String(localStorage.getItem('sessionId')),
        },
      })
      .subscribe({
        next: (result) => {
          console.log(result);
          Swal.fire('Success!', 'Récompense ajoutée avec succès', 'success');
          this.dialogRef.close();
        },
        error: (error) => {
          console.log(error);
          this.dialogRef.close();
          Swal.fire('Error!', 'Une erreur est survenue', 'error');
        },
      });
  }

  deleteReward( id: number) {
    Swal.fire({
      title: 'Est-ce que vous êtes sûr?',
      text: 'Voulez-vous vraiment supprimer cette récompense?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer!',
      cancelButtonText: 'Non, annuler!',
    }).then((result) => {
      if (result.value) {
        this.rewardService.deleteReward(id)
          .subscribe({
            next: (result) => {
              console.log(result.data);
              Swal.fire(
                'Supprimé!',
                'La récompense a été supprimée.',
                'success'
              );
              this.dialogRef.close();
            },
            error: (error) => {
              Swal.fire('Error!', 'Une erreur est survenue', 'error');
            },
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'La récompense n\'a pas été supprimée', 'error');
      }
    });
  }


  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get(event.target.name)?.setValue(file);
    }
  }

  getRewardForPosition(position: number) {
    return this.data.challenge.rewards.find(
      (reward: any) => reward.assignedRankingPosition === position
    );
  }

}
