import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { DELETE_REWARD } from '../graphql/reward/mutations';

@Injectable({
  providedIn: 'root'
})
export class RewardService {

  constructor(private apollo: Apollo) { }

  deleteReward(rewardId: number) {
    return this.apollo.mutate({
      mutation: DELETE_REWARD,
      variables: {
        rewardId: rewardId
      }
    })
  }
}
