<h2 mat-dialog-title>{{data.challenge.name}}</h2>
<mat-dialog-content class="mat-typography">
    <form class="m-t-30" #rewardForm="ngForm">

        <div class="d-flex justify-content-start m-r-20">

            <div class="col-4">
                <h2 class="m-0 mat-headline-2 f-w-600 m-b-16">Ajouter une récompense</h2>
                <span class="m-l-16 mat-body-1 f-s-12">Image descriptive de la récompense</span>
                <div class="bb-input-file">
                    <input type="file" accept="image/*" ngModel name="image" class="w-100"
                        (change)="onFileSelect($event)" required />
                </div> <br />

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Position </mat-label>
                    <input matInput type="number" ngModel name="assignedRankingPosition" required #assignedRankingPosition min="1" max="3" maxlength="1" />
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Libellé de la récompense </mat-label>
                    <input matInput ngModel name="name" required #nameInput />
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Description</mat-label>
                    <textarea matInput ngModel name="description" required #description maxlength="400"
                        rows="6"></textarea>
                    <mat-hint class="f-s-10" align="end"></mat-hint>
                </mat-form-field>

                <div class="d-flex justify-content-between m-10">

                    <button mat-flat-button color="primary" class="w-100" (click)="addReward(rewardForm)">
                        <mat-icon>save</mat-icon>
                        Ajouter la récompense
                    </button>

                </div>

            </div>


            <div class="col-8">
                <h2 class="m-0 mat-headline-2 f-w-600 m-b-16">Liste des récompenses</h2>
                <br />
                <div class="d-flex justify-content-between">
                    <div class="col" *ngFor="let position of [1, 2, 3]">

                        <h2 class="m-0 mat-headline-2 f-w-600 m-b-16">#{{ position }}</h2>
                        
                        <ng-container *ngIf="getRewardForPosition(position) as reward; else noReward">
                            <img [hidden]="!reward" [src]="reward.imageUrl" width="180" class="rounded" />
                        
                            <div>
                                <h6 class="mat-subtitle-1 f-s-14 f-w-600 text-primary">{{ reward.name }}</h6>
                                <span class="mat-body-1">{{ reward.description }}</span>
                            </div>

                            <button mat-flat-button color="primary" class="w-100 m-t-30" (click)="deleteReward(reward.id)">
                                <mat-icon>delete</mat-icon>
                                Supprimer la récompense
                            </button>
                        
                        </ng-container>
                        
                        <ng-template #noReward>
                            <div class="no-reward">
                                <h6 class="mat-subtitle-1 f-s-14 f-w-600 text-muted">Pas de récompense</h6>
                            </div>
                        </ng-template>

                    </div>
                </div>
            </div>

        </div>

    </form>

</mat-dialog-content>


<!-- buttons -->
<mat-dialog-actions class="m-10" align="end">

    <button mat-button mat-dialog-close>Annuler</button>

</mat-dialog-actions>