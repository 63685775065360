import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgZone } from '@angular/core';
import { timer } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'BattleBox';
  private subscription: any;

  constructor(private ngZone: NgZone, private loginService: LoginService) {}

  ngOnInit() {
    this.subscription = timer(0, 300000) // vérifier toutes les 5 minutes
      .subscribe(() => {
        this.ngZone.runOutsideAngular(() => {
          this.checkAndDisconnectUser();
        });
      });

    localStorage.setItem('sessionId', uuid());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  checkAndDisconnectUser() {

    // verifier si l'historique de connexion est disponible
    if (localStorage.getItem('loginHistory') === null) {
      // si l'historique de connexion n'est pas disponible, on le crée
      localStorage.setItem('loginHistory', JSON.stringify([]));
    }

    // récupérer l'historique de connexion
    const loginHistory = JSON.parse(localStorage.getItem('loginHistory') ?? '');

    if (!loginHistory) return; //si l'historique de connexion n'est pas disponible, ne rien faire

    // calcule la date de connexion la plus ancienne
    const earliestLoginDate = new Date(Math.min(...loginHistory.map((date: string) => new Date(date))));
    const today = new Date();

    // calcule le nombre de jours depuis la date de connexion la plus ancienne
    const daysSinceEarliestLogin = Math.floor((today.getTime() - earliestLoginDate.getTime()) / (1000 * 60 * 60 * 24));

    // si l'utilisateur n'a pas été actif depuis 1 jour, on le déconnecte
    if (daysSinceEarliestLogin >= 1) {
      this.loginService.SignOut();
      window.location.href = '/auth/login';
    }
  }
}
