import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GET_AVAILABLE_QUESTS } from '../graphql/quest/queries';
import { CREATE_QUEST, CREATE_QUEST_OBJECTIVE, DELETE_QUEST, DELETE_QUEST_OBJECTIVE } from '../graphql/quest/mutations';

const PUBLISH_CHALLENGE = 'publish_challenge';
const PUBLISH_PARTICIPATION = 'publish_participation';
const COMMENT_CHALLENGE = 'comment_challenge';
const COMMENT_PARTICIPATION = 'comment_participation';
const LIKE_CHALLENGE = 'like_challenge';
const LIKE_PARTICIPATION = 'like_participation';
const SHARE_CHALLENGE = 'share_challenge';
const SHARE_PARTICIPATION = 'share_participation';
const FOLLOW_USER = 'follow_user';
const ADD_PROFILE_AVATAR = 'add_profile_avatar';

export const OBJECTIVES_ACTIONS = [
  PUBLISH_CHALLENGE,
  PUBLISH_PARTICIPATION,
  COMMENT_CHALLENGE,
  COMMENT_PARTICIPATION,
  LIKE_CHALLENGE,
  LIKE_PARTICIPATION,
  SHARE_CHALLENGE,
  SHARE_PARTICIPATION,
  FOLLOW_USER,
  ADD_PROFILE_AVATAR,
];

@Injectable({
  providedIn: 'root'
})
  
export class QuestService {
  static OBJECTIVES_ACTIONS: any = OBJECTIVES_ACTIONS;

  constructor(private apollo: Apollo) { }

  getAvailableQuests() {
    return this.apollo.query({
      query: GET_AVAILABLE_QUESTS
    })
  }

  createQuest(questData: {
    name: string,
    active: boolean,
    startDate: string,
    endDate: string
  }) {
    return this.apollo.mutate({
      mutation: CREATE_QUEST,
      variables: {
        infos: questData
      },
      refetchQueries: [{
        query: GET_AVAILABLE_QUESTS
      }]
    })
  }

  createQuestObjective(objectiveData: {
    questId: number,
    active: boolean,
    description: string,
    earnableTrophies: number,
    requiredAction: string,
    requiredNumber: number,
    targetId?: string
  }) {
    if (!OBJECTIVES_ACTIONS.includes(objectiveData.requiredAction)) {
      throw new Error('Invalid action');
    }

    var infos = {
      active: objectiveData.active,
      description: objectiveData.description,
      earnableTrophies: objectiveData.earnableTrophies,
      requiredAction: objectiveData.requiredAction,
      requiredNumber: objectiveData.requiredNumber,
    }

    if (objectiveData.targetId) {
      
    }

    return this.apollo.mutate({
      mutation: CREATE_QUEST_OBJECTIVE,
      variables: {
        questId: objectiveData.questId,
        infos: infos
      },
      refetchQueries: [{
        query: GET_AVAILABLE_QUESTS
      }]
    })
  }

  deleteQuest(questId: number) {
    return this.apollo.mutate({
      mutation: DELETE_QUEST,
      variables: {
        questId: questId
      }
    })
  }

  deleteQuestObjective(objectiveId: number) {
    return this.apollo.mutate({
      mutation: DELETE_QUEST_OBJECTIVE,
      variables: {
        objectiveId: objectiveId
      }
    })
  }

}
