import { gql } from '@apollo/client/core';

export const CREATE_QUEST = gql`
    mutation createQuest($infos: NewQuestInput!) {
        createQuest(infos: $infos) {
          id
        }
    }
`;

export const CREATE_QUEST_OBJECTIVE = gql`
    mutation createQuestObjective($questId: Int!, $infos: NewQuestObjectiveInput!) {
        createQuestObjective(questId: $questId, infos: $infos) {
            id
        }
    }
`;

export const DELETE_QUEST = gql`
    mutation deleteQuest($questId: Int!) {
        deleteQuest(questId: $questId)
    }
`;

export const DELETE_QUEST_OBJECTIVE = gql`
    mutation deleteQuestObjective($objectiveId: Int!) {
        deleteQuestObjective(objectiveId: $objectiveId)
    }
`;
