import { NavItem } from './nav-item/nav-item';

export const navItems: NavItem[] = [
  {
    navCap: 'Home',
  },
  {
    displayName: 'Dashboard',
    iconName: 'layout-dashboard',
    route: '/dashboard',
  },
  {
    displayName: 'Recherche',
    iconName: 'search',
    route: '/search',
  },
  {
    navCap: 'Gestion',
  },
  {
    displayName: 'Challenge',
    iconName: 'run',
    route: '/challenges',
  },
  {
    displayName: 'Quêtes',
    iconName: 'trophy',
    route: '/quests',
  },
  {
    displayName: 'Signalements',
    iconName: 'report',
    route: '/signalements',
  },
  {
    displayName: 'Propositions',
    iconName: 'bulb',
    route: '/propositions',
  },
  {
    navCap: 'paramètres',
  },
  {
    displayName: 'Accès Admin',
    iconName: 'key',
    route: '/access',
  },
];
